import styles from './styles.module.scss';

import { forwardRef } from 'react';

type Props<Name extends string> = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  name: Name;
};

const SearchTextFieldPresenterContent = forwardRef<
  HTMLInputElement,
  Props<string>
>((props, ref) => {
  return (
    <>
      {props.label && (
        <label htmlFor={props.name} className={styles.label}>
          {props.label}
        </label>
      )}
      <div className={styles.inputWrapper}>
        <input
          {...props}
          id={props.name}
          className={styles.input}
          ref={ref}
          inputMode="search"
        />
      </div>
    </>
  );
});
export const SearchTextFieldPresenter: <Name extends string>(
  props: React.PropsWithoutRef<Props<Name>> &
    React.RefAttributes<HTMLInputElement>,
) => ReturnType<typeof SearchTextFieldPresenterContent> =
  SearchTextFieldPresenterContent;
