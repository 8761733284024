import styles from './styles.module.scss';

import { forwardRef } from 'react';

type Props = {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'disabled' | 'tabIndex' | 'checked'
>;

export const Radio = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <span className={styles.wrapper}>
    <input
      type="radio"
      name={props.name}
      value={props.value}
      className={styles.input}
      ref={ref}
      onChange={props.onChange}
      tabIndex={props.tabIndex}
      disabled={props.disabled}
      checked={props.checked}
    />
    <span className={styles.base} />
  </span>
));
