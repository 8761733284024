import { Input } from '@/src/components/foundations/Forms/Input';
import { usePopup } from '@/src/hooks/usePopup';

import { OptionList } from './OptionList';
import styles from './styles.module.scss';

import clsx from 'clsx';
import { forwardRef, useEffect, useRef, useState } from 'react';

type OverrideStandardInputProps = Pick<
  React.HTMLAttributes<Element>,
  'tabIndex' | 'placeholder'
>;

export type InputOfInputWithOptionListProps = Pick<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  | 'onFocus'
  | 'onChange'
  | 'onCompositionStart'
  | 'onCompositionEnd'
  | 'onKeyDown'
> & {
  name: string;
} & OverrideStandardInputProps;
type OptionListProps = React.ComponentProps<typeof OptionList>;
type Props = {
  Input: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<InputOfInputWithOptionListProps> &
      React.RefAttributes<HTMLInputElement> &
      Pick<React.ComponentProps<typeof Input>, 'hasError' | 'isDisabled'>
  >;
  name: string;
  value: string;
  setValue: (value: Props['value']) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validatingOptions?: OptionListProps['options'];
  optionListWrapperClassName?: string;
} & Pick<OptionListProps, 'optionIdPrefix' | 'options'> &
  OverrideStandardInputProps &
  Pick<React.ComponentProps<typeof Input>, 'hasError' | 'isDisabled'>;

export const InputWithOptionList = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const popup = usePopup<HTMLDivElement>();
    const [optionIndex, setOptionIndex] = useState(0);
    const isComposingRef = useRef(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.(e);

      if (!popup.isShown) {
        popup.show();
      }
    };

    const onCompositionStart = () => {
      isComposingRef.current = true;
    };

    const onCompositionEnd = () => {
      isComposingRef.current = false;
    };

    const onKeyDown: InputOfInputWithOptionListProps['onKeyDown'] = (event) => {
      switch (event.key) {
        case 'ArrowDown':
          setOptionIndex((index) =>
            Math.min(index + 1, props.options.length - 1),
          );
          break;
        case 'ArrowUp':
          setOptionIndex((index) => Math.max(0, index - 1));
          break;
        case 'Enter': {
          if (!isComposingRef.current) {
            const prefecture = props.options[optionIndex];
            if (prefecture != null) {
              props.setValue(prefecture);
            }
          }
          break;
        }
      }
    };

    // optionListを表示 (true) → 非表示 (false) にするタイミングで、 value をチェックし、 clear したい意図だが、
    // useEffect の挙動的に、 isShown (usePopup > useFlag) を false に初期化するときに実行されてしまう。
    // isShown の初期化のタイミングで、 かならず value clear されてしまうことを避けるために ref を利用する
    const isMountedRef = useRef(false);
    useEffect(() => {
      if (!isMountedRef.current) {
        isMountedRef.current = true;
        return;
      }

      if (popup.isShown) {
        return;
      }

      if (!props.validatingOptions?.some((option) => option === props.value)) {
        props.setValue('');
      }

      setOptionIndex(0);
    }, [popup.isShown]);

    return (
      <div className={styles.base} ref={popup.containerRef}>
        <props.Input
          name={props.name}
          onFocus={popup.show}
          onChange={onChange}
          onCompositionStart={onCompositionStart}
          onCompositionEnd={onCompositionEnd}
          onKeyDown={onKeyDown}
          tabIndex={props.tabIndex}
          placeholder={props.placeholder}
          hasError={props.hasError}
          isDisabled={props.isDisabled}
          ref={ref}
        />
        {popup.isShown && (
          <div
            className={clsx(
              styles.optionListWrapper,
              props.optionListWrapperClassName,
            )}
          >
            <OptionList
              optionIdPrefix={props.optionIdPrefix}
              options={props.options}
              focusedOptionIndex={optionIndex}
              focusOptionIndex={setOptionIndex}
              onClickOption={props.setValue}
            />
          </div>
        )}
      </div>
    );
  },
);
