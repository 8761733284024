import { prefectures } from '@/src/utils/prefectures';

import { PrefectureSearchTextFieldPresenter } from './presenter';

import { forwardRef, useMemo } from 'react';

type PrefectureSearchTextFieldPresenterProps = React.ComponentProps<
  typeof PrefectureSearchTextFieldPresenter
>;
type Props = Omit<
  PrefectureSearchTextFieldPresenterProps,
  'options' | 'validatingOptions'
>;

export const PrefectureSearchTextField: React.FC<Props> = forwardRef<
  HTMLInputElement,
  Props
>((props, ref) => {
  const options = useMemo(
    () =>
      prefectures
        .filter((p) => {
          const prefecture = props.value;
          return (
            p.name !== prefecture &&
            (p.name.startsWith(prefecture) || p.kana.startsWith(prefecture))
          );
        })
        .map(({ name }) => name),
    [props.value],
  );

  const validatingOptions = useMemo(
    () => prefectures.map(({ name }) => name),
    [],
  );

  return (
    <PrefectureSearchTextFieldPresenter
      options={options}
      validatingOptions={validatingOptions}
      {...props}
      ref={ref}
    />
  );
});
