import styles from './styles.module.scss';

import clsx from 'clsx';
import { forwardRef } from 'react';

type Props<Name extends string> = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  name: Name;
  type?: 'email' | 'password' | 'text' | 'number';
  autoComplete?: 'current-password' | 'new-password' | 'off';
  hasError?: boolean;
  isDisabled?: boolean;
  isPastingForbidden?: boolean;
} & Pick<React.CSSProperties, 'width'>;

const InputContent = forwardRef<HTMLInputElement, Props<string>>(
  (props, ref) => {
    const { width, hasError, isDisabled, isPastingForbidden, ...inputProps } =
      props;

    return (
      <input
        {...inputProps}
        id={props.name}
        disabled={isDisabled}
        onPaste={(event) => {
          if (isPastingForbidden) {
            event.preventDefault();
          }
        }}
        className={clsx(styles.base, hasError && styles.error)}
        style={{ width }}
        onWheel={(event) => event.currentTarget.blur()}
        ref={ref}
      />
    );
  },
);
export const Input: <Name extends string>(
  props: React.PropsWithoutRef<Props<Name>> &
    React.RefAttributes<HTMLInputElement>,
) => ReturnType<typeof InputContent> = InputContent;
