import {
  InputWithOptionList,
  InputOfInputWithOptionListProps,
} from '@/src/components/blocks/InputWithOptionList';
import { SearchTextField } from '@/src/components/blocks/SearchTextField';
import { Input } from '@/src/components/foundations/Forms/Input';

import styles from './styles.module.scss';

import { forwardRef } from 'react';

type InputWithOptionListProps = React.ComponentProps<
  typeof InputWithOptionList
>;
type Props = Omit<
  InputWithOptionListProps,
  'Input' | 'optionListWrapperClassName'
> & { mode: 'inline' | 'normal' } & Pick<
    React.ComponentProps<typeof Input>,
    'width' | 'hasError'
  >;

const PrefectureInlineInputForm = forwardRef<
  HTMLInputElement,
  InputOfInputWithOptionListProps
>((props, ref) => (
  <SearchTextField
    label="都道府県"
    autoComplete="off"
    placeholder="都道府県"
    {...props}
    ref={ref}
  />
));

const PrefectureInputForm = forwardRef<
  HTMLInputElement,
  InputOfInputWithOptionListProps
>((props, ref) => (
  <Input autoComplete="off" placeholder="都道府県" {...props} ref={ref} />
));

export const PrefectureSearchTextFieldPresenter: React.FC<Props> = forwardRef<
  HTMLInputElement,
  Props
>((props, ref) => (
  <InputWithOptionList
    Input={(() => {
      switch (props.mode) {
        case 'normal':
          return PrefectureInputForm;
        case 'inline':
          return PrefectureInlineInputForm;
      }
    })()}
    optionListWrapperClassName={
      props.mode === 'inline' ? styles.prefectureOptionListWrapper : undefined
    }
    {...props}
    ref={ref}
  />
));
