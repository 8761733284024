import { Input } from '@/src/components/foundations/Forms/Input';

import styles from './styles.module.scss';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { forwardRef } from 'react';

type Props = React.ComponentProps<typeof Input> & {
  up: VoidFunction;
  down: VoidFunction;
};

export const NumberInputPresenterContent = forwardRef<HTMLInputElement, Props>(
  ({ up, down, ...inputProps }, ref) => (
    <div className={styles.base} style={{ width: inputProps.width }}>
      <Input type="number" ref={ref} {...inputProps} />
      <span className={styles.buttonsContainer}>
        <button
          type="button"
          onClick={up}
          className={styles.button}
          disabled={inputProps.isDisabled}
        >
          <ArrowDropUp />
        </button>
        <button
          type="button"
          onClick={down}
          className={styles.button}
          disabled={inputProps.isDisabled}
        >
          <ArrowDropDown />
        </button>
      </span>
    </div>
  ),
);
export const NumberInputPresenter: (
  props: React.PropsWithoutRef<Props> & React.RefAttributes<HTMLInputElement>,
) => ReturnType<typeof NumberInputPresenterContent> =
  NumberInputPresenterContent;
