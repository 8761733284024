import { NumberInputPresenter } from './presenter';

import { forwardRef, useRef, useImperativeHandle } from 'react';
import { ChangeHandler } from 'react-hook-form';

type Props = Omit<
  React.ComponentProps<typeof NumberInputPresenter>,
  'up' | 'down' | 'onChange'
> & {
  // up, downでonChangeを呼び出したいが、HTMLInputElement由来のonChangeを呼び出すためのイベント作成は難易度が高い。
  // foro CRMでは多くの場合RHFに渡せれば問題ないので、RHFで必要としている型で定義しなおしたonChangeを使う。
  // HTMLInputElement同様、必ずしも設定される必要はないので optional にしていることに注意。
  onChange?: ChangeHandler;
};

const NumberInputContent = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
  const up = () => {
    if (innerRef.current == null) {
      return;
    }

    // value は NaN の可能性がある。その場合 0 に変換して計算する
    const incrementedByOneValue = (Number(innerRef.current?.value) || 0) + 1;

    const newValue =
      props.max != null
        ? Math.min(incrementedByOneValue, props.max as number)
        : incrementedByOneValue;

    innerRef.current.value = newValue.toString();

    // react-hook-formの register で登録されている onChange を直接呼び出す
    props.onChange?.({ target: innerRef.current, type: 'change' });
  };
  const down = () => {
    if (innerRef.current == null) {
      return;
    }

    const decrementedByOneValue = (Number(innerRef.current?.value) || 0) - 1;

    const newValue =
      props.min != null
        ? Math.max(decrementedByOneValue, props.min as number)
        : decrementedByOneValue;

    innerRef.current.value = newValue.toString();

    // react-hook-formの register で登録されている onChange を直接呼び出す
    props.onChange?.({ target: innerRef.current, type: 'change' });
  };

  return <NumberInputPresenter {...props} up={up} down={down} ref={innerRef} />;
});
export const NumberInput: (
  props: React.PropsWithoutRef<Props> & React.RefAttributes<HTMLInputElement>,
) => ReturnType<typeof NumberInputContent> = NumberInputContent;
