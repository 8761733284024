import { Label } from '@/src/components/foundations/DataDisplays/Label';
import { Checkbox } from '@/src/components/foundations/Forms/Checkbox';

import styles from './styles.module.scss';

import clsx from 'clsx';
import { forwardRef } from 'react';

type Props<Name extends string> = {
  name: Name;
  options: { label: string; value: string }[];
  gap: {
    row: number;
    column: number;
  };
  isVertical?: boolean;
} & Pick<
  React.ComponentProps<typeof Checkbox>,
  'onChange' | 'tabIndex' | 'isDisabled'
>;

const CheckboxListContent = forwardRef<HTMLInputElement, Props<string>>(
  (props, ref) => {
    return (
      <ul
        className={clsx(styles.base, props.isVertical && styles.isVertical)}
        style={{
          rowGap: props.gap.row,
          columnGap: props.gap.column,
        }}
      >
        {/* MEMO: 同じ ref 属性を持つ checkbox が 1 つのみとなる場合、RHF は boolean を返してしまう */}
        {/* このコンポーネントが期待したい string[] として返す為には、ダミー要素を用意し checkbox を必ず 2 つ以上にする */}
        <li className={styles.hidden}>
          {/* ダミー要素は非表示 */}
          <Checkbox
            name={props.name}
            value=""
            ref={ref}
            onChange={() => {}}
            isDisabled
            tabIndex={props.tabIndex}
          />
        </li>

        {props.options.map((o) => (
          <li key={o.label} className={styles.listItem}>
            <Label
              text={o.label}
              position="right"
              distance={4}
              typography={{
                fontSize: 14,
                fontWeight: 'normal',
                color: props.isDisabled ? 'gray20Alpha' : 'gray100',
              }}
            >
              <Checkbox
                name={props.name}
                value={o.value}
                ref={ref}
                onChange={props.onChange}
                tabIndex={props.tabIndex}
                isDisabled={props.isDisabled}
              />
            </Label>
          </li>
        ))}
      </ul>
    );
  },
);
export const CheckboxList: <Name extends string>(
  props: React.PropsWithoutRef<Props<Name>> &
    React.RefAttributes<HTMLInputElement>,
) => ReturnType<typeof CheckboxListContent> = CheckboxListContent;
