import { useFlag } from '@/src/hooks/useFlag';

import { useEffect, useRef } from 'react';

export const usePopup = <Container extends HTMLElement>() => {
  const [isShown, show, hide] = useFlag();

  const containerRef = useRef<Container>(null);

  const onClickOutsideContainer = (event: MouseEvent) => {
    if (!containerRef.current?.contains(event.target as Node)) {
      hide();
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClickOutsideContainer);

    return () => {
      window.removeEventListener('click', onClickOutsideContainer);
    };
  }, []);

  return {
    isShown,
    show,
    hide,
    containerRef,
  };
};
