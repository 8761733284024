import { Label } from '@/src/components/foundations/DataDisplays/Label';
import { Radio } from '@/src/components/foundations/Forms/Radio';

import styles from './styles.module.scss';

import clsx from 'clsx';
import { forwardRef } from 'react';

const Orientation = {
  vertical: 'vertical',
  horizontal: 'horizontal',
} as const;

type RadioProps = React.ComponentProps<typeof Radio>;
type Orientation = keyof typeof Orientation;
type Props<Name extends RadioProps['name']> = {
  name: Name;
  radios: ({ label: string } & Pick<RadioProps, 'value'>)[];
  distance: number;
  orientation?: Orientation;
} & Pick<RadioProps, 'onChange' | 'tabIndex' | 'disabled'> &
  (ControlledProps | UncontrolledProps);

type ControlledProps = {
  /** 現在 controlled component 利用時のみ利用している */
  currentValue: string | null;
};
type UncontrolledProps = {
  currentValue?: never;
};

export const RadioGroupContent = forwardRef<HTMLInputElement, Props<string>>(
  (props, ref) => {
    const { orientation = Orientation.vertical } = props;

    return (
      <div
        className={clsx(styles.base, styles[orientation])}
        style={{ gap: props.distance || 0 }}
      >
        {props.radios.map((radio, index) => (
          <div key={index} className={styles.items}>
            <Label
              text={radio.label}
              position="right"
              distance={4}
              typography={{
                fontSize: 14,
                fontWeight: 'normal',
                color: props.disabled ? 'gray20Alpha' : 'gray100',
              }}
            >
              <Radio
                name={props.name}
                value={radio.value}
                ref={ref}
                onChange={props.onChange}
                tabIndex={props.tabIndex}
                disabled={props.disabled}
                checked={
                  // controlled component 利用時は undefined が渡されてはいけない
                  props.currentValue !== undefined
                    ? props.currentValue === radio.value
                    : undefined
                }
              />
            </Label>
          </div>
        ))}
      </div>
    );
  },
);
export const RadioGroup: <Name extends string>(
  props: React.PropsWithoutRef<Props<Name>> &
    React.RefAttributes<HTMLInputElement>,
) => ReturnType<typeof RadioGroupContent> = RadioGroupContent;
