import styles from './styles.module.scss';

import clsx from 'clsx';
import { useEffect, useRef } from 'react';

type Props = {
  optionIdPrefix: string;
  options: string[];
  focusedOptionIndex: number;
  focusOptionIndex: (index: Props['focusedOptionIndex']) => void;
  onClickOption: (option: Props['options'][number]) => void;
};

export const OptionList: React.FC<Props> = (props) => {
  const containerElRef = useRef<HTMLUListElement>(null);

  const optionId = (index: Props['focusedOptionIndex']) =>
    `${props.optionIdPrefix}-${index}`;

  useEffect(() => {
    const containerEl = containerElRef.current;
    const optionEl = document.getElementById(
      optionId(props.focusedOptionIndex),
    );

    if (
      containerEl != null &&
      optionEl != null &&
      containerEl.scrollHeight > containerEl.clientHeight
    ) {
      const scrollBottom = containerEl.clientHeight + containerEl.scrollTop;
      const elementBottom = optionEl.offsetTop + optionEl.offsetHeight;

      if (elementBottom > scrollBottom) {
        containerEl.scrollTop = elementBottom - containerEl.clientHeight;
      } else if (optionEl.offsetTop < containerEl.scrollTop) {
        containerEl.scrollTop = optionEl.offsetTop;
      }
    }
  }, [props.focusedOptionIndex]);

  useEffect(() => {
    props.focusOptionIndex(0);
  }, [props.options]);

  return (
    <ul
      aria-activedescendant={optionId(props.focusedOptionIndex)}
      className={clsx(styles.base, props.options.length === 0 && styles.empty)}
      ref={containerElRef}
    >
      {props.options.map((option, index) => (
        <li
          key={option}
          id={optionId(index)}
          onMouseOver={() => {
            props.focusOptionIndex(index);
          }}
          onClick={() => {
            props.onClickOption(option);
          }}
          className={clsx(
            styles.option,
            index === props.focusedOptionIndex && styles.focus,
          )}
        >
          {option}
        </li>
      ))}
    </ul>
  );
};
